<template>
  <aside
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 flex justify-end w-full left-full"
  >
    <div
      data-dialog-box
      class="h-full overflow-y-auto px-5 py-5 bg-white max-w-[90%] w-[500px] flex flex-col relative z-10"
    >
      <div class="flex items-center justify-between mb-5">
        <div>
          <p class="text-3xl font-bold uppercase text-primary">ShopCart</p>
          <p class="text-sm font-medium text-black">購物車</p>
        </div>
        <p class="text-sm font-bold">{{ shopcart_count }} 項目</p>
      </div>
      <ol class="flex-1 w-full">
        <li
          v-for="(item, item_index) in shop_cart"
          :key="`shop_item_${item_index}`"
          class="flex items-start w-full py-5 border-b border-zinc-100"
        >
          <div class="w-1/4 overflow-hidden rounded-md aspect-square">
            <img
              class="object-cover w-full h-full"
              :alt="item.product_data.Title"
              :src="$ImageUrl(item.product_data.Image1)"
            />
          </div>
          <div class="flex-1 pl-5">
            <p class="mb-1 text-sm font-bold md:text-base text-primary">
              {{ item.product_data.Title }}
            </p>
            <p
              class="text-xs font-medium"
              v-if="GetActiveOption(item).ColorTitle != '無'"
            >
              {{ item.product_data.Option1 }}：<span>{{
                GetActiveOption(item).ColorTitle
              }}</span>
            </p>
            <p
              class="text-xs font-medium"
              v-if="GetActiveOption(item).SizeTitle != '無'"
            >
              {{ item.product_data.Option2 }}：<span>{{
                GetActiveOption(item).SizeTitle
              }}</span>
            </p>
            <div class="flex items-center mt-3">
              <button
                @click="Remove(item_index, 1)"
                class="flex items-center justify-center w-5 h-5 transition-colors duration-300 border-2 rounded-full text-primary border-primary hover:md:bg-primary hover:md:text-white"
              >
                <i class="text-xs icon-minus"></i>
              </button>
              <input
                type="text"
                v-model="item.amount"
                class="w-10 text-center"
              />

              <button
                @click="Add(item_index)"
                class="flex items-center justify-center w-5 h-5 transition-colors duration-300 border-2 rounded-full text-primary border-primary hover:md:bg-primary hover:md:text-white"
              >
                <i class="text-xs icon-plus"></i>
              </button>
            </div>
          </div>
          <div class="flex flex-col items-end flex-shrink-0 ml-5 text-right">
            <p v-if="!is_member" class="mb-1 text-sm font-bold md:text-base">
              NT$
              {{
                $MoneyFormat(
                  parseInt(GetActiveOption(item).SellPrice) * item.amount
                )
              }}
            </p>
            <p v-else class="mb-1 font-bold text-primary">
              NT$
              {{
                $MoneyFormat(
                  parseInt(GetActiveOption(item).MemberSellPrice) * item.amount
                )
              }}
            </p>
            <button
              @click="Remove(item_index, item.amount)"
              class="flex items-center justify-center w-8 h-8 transition-all duration-300 border rounded-full bg-opacity-20 text-primary bg-primary border-primary hover:md:bg-opacity-100 hover:md:text-white"
            >
              <i class="icon-close"></i>
            </button>
          </div>
        </li>
      </ol>

      <div class="pt-5 border-t border-zinc-300">
        <p class="mb-5 text-xs">折扣、運費與交易手續費將會在結帳頁面進行計算</p>
        <button
          @click="
            $router.push('/checkout'), $store.commit('SetShopCartStatus', false)
          "
          class="block w-full py-4 font-medium text-center text-white transition-all duration-300 border rounded-lg bg-primary hover:md:bg-transparent hover:md:text-primary border-primary"
        >
          結帳
        </button>
      </div>
    </div>

    <div
      data-dialog-background
      @click="$store.commit('SetShopCartStatus', false)"
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-50"
    ></div>
  </aside>
</template>

<script>
import { ShopCart } from "@/gsap/ShopCart";
import { getLocalStorage } from "@/common/cookie";
export default {
  name: "ShopCartDrawer",
  data() {
    return {
      dialog_gsap: null,
    };
  },
  methods: {
    GetActiveOption(shopcart_item) {
      const stock = shopcart_item.product_data.Stock.filter((item) => {
        return (
          item.ColorID == shopcart_item.active_option[0] &&
          item.SizeID == shopcart_item.active_option[1]
        );
      });
      return stock.length > 0 ? stock[0] : "error";
    },
    Add(index) {
      // GTM_AddShopCart(this.shopcart[index], 1);
      const shop_cart_item = {
        product: this.shop_cart[index].product_data,
        options: this.shop_cart[index].active_option,
        amount: 1,
      };
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/AddShopCart", shop_cart_item);
      } else {
        this.$store.commit("shopcart_module/AddShopCart", shop_cart_item);
      }
    },
    Remove(index, count, type = "normal_product") {
      if (type == "normal_product") {
        // GTM_RemoveShopCart(this.shopcart[index], count);
      }
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      } else {
        this.$store.commit("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      }
    },
  },
  computed: {
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token");
    },
    shop_cart_open() {
      return this.$store.state.shop_cart_open;
    },
    shop_cart() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shopcart_count() {
      let count = 0;
      this.$store.state.shopcart_module.shopcart.forEach((item) => {
        count += item.amount;
      });
      return count;
    },
  },
  watch: {
    shop_cart_open() {
      if (this.shop_cart_open) {
        this.dialog_gsap.open();
      } else {
        this.dialog_gsap.close();
      }
    },
  },
  mounted() {
    this.dialog_gsap = new ShopCart(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
