import store from "@/store/index";
import router from "@/router/index";

var page_name_list = {
  首頁: "home",
  最新消息列表: "newslist",
  商品列表: "productlist",
  關於我們: "about",
  聯絡我們: "contact",
};

export function GetMatchMetaData() {
  const column_title = page_name_list[router.history.current.name];
  let meta_data_list = store.state.common_column_data;
  let title = meta_data_list.filter(
    (item) => item.Title == `meta_title_${column_title}`
  );
  let content = meta_data_list.filter(
    (item) => item.Title == `meta_content_${column_title}`
  );
  let image = meta_data_list.filter(
    (item) => item.Title == `meta_image_${column_title}`
  );
  if (title.length > 0) {
    return {
      title: title[0].Content,
      content: content[0].Content.replaceAll(/<[^>]+>/g, ""),
      image: GetImageUrl(image[0].Content),
    };
  } else {
    return false;
  }
}

export function GetBaseMetaData() {
  let meta_data_list = store.state.common_column_data;
  let base_title = meta_data_list.filter(
    (item) => item.Title == "meta_title_home"
  )[0];
  let base_content = meta_data_list.filter(
    (item) => item.Title == "meta_content_home"
  )[0];
  let base_image = meta_data_list.filter(
    (item) => item.Title == "meta_image_home"
  )[0];
  return {
    title: base_title.Content,
    content: base_content.Content.replaceAll(/<[^>]+>/g, ""),
    image: GetImageUrl(base_image.Content),
  };
}

export function GetMetaData(title, content = "", image = "") {
  let meta_data = GetMatchMetaData();
  meta_data == false ? (meta_data = GetBaseMetaData()) : "";
  title != "" ? (meta_data.title = title) : "";
  content != "" ? (meta_data.content = content) : "";
  image != "" ? (meta_data.image = image) : "";

  meta_data.content.length > 155
    ? (meta_data.content = meta_data.content.slice(0, 155) + "...")
    : "";
  return {
    title: meta_data.title + " | 快樂買購物商城",
    meta: [
      {
        property: "og:url",
        name: "og:url",
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: "og:url",
      },
      {
        property: "og:title",
        name: "og:title",
        content: meta_data.title + " | 快樂買購物商城",
        vmid: "og:title",
      },
      {
        property: "og:description",
        name: "og:description",
        content: meta_data.content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        name: "twitter:description",
        content: meta_data.content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        name: "description",
        content: meta_data.content,
        vmid: "description",
      },
      {
        property: "og:image",
        name: "og:image",
        content: meta_data.image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: meta_data.title + " | 快樂買購物商城",
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        name: "application-name",
        content: meta_data.title + " | 快樂買購物商城",
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content: "官方網站" + " | 快樂買購物商城",
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        name: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
}

function GetImageUrl(item) {
  return item == "" ? "" : process.env.VUE_APP_BASE_API + item;
}
