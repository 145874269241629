<template>
  <section class="relative z-10 w-full py-20">
    <div class="w-full max-w-[1440px] mx-auto relative z-10 md:px-10 px-5">
      <header class="w-full text-center mb-14">
        <h2 class="mb-1 text-3xl font-black md:text-4xl">最新消息</h2>
        <span class="block mb-3 font-bold text-primary">NEW INFORMATION</span>
      </header>

      <ol class="flex flex-wrap items-stretch mb-10 -mx-5">
        <li
          v-for="(item, item_index) in page_news_data"
          :key="`news_${item_index}`"
          class="w-full px-5 mb-10 lg:w-1/2"
        >
          <div class="flex flex-col w-full h-full">
            <router-link
              :to="`/news/${item.NewsID}`"
              class="block w-full mb-5 overflow-hidden aspect-video rounded-xl"
            >
              <img
                :src="$ImageUrl(item.Image)"
                class="block w-full transition-all duration-500 transform hover:md:scale-105"
              />
            </router-link>
            <h4 class="mb-1 text-xl font-bold sm:text-2xl text-zinc-800">
              {{ item.Title }}
            </h4>
            <div class="flex items-center w-full mb-3">
              <p
                class="px-2 py-1 mr-3 text-xs font-bold text-white rounded-full bg-secondary"
              >
                {{ item.CategoryTitle }}
              </p>
              <p class="text-sm font-bold">
                {{ item.Date }}
              </p>
            </div>
            <div class="flex-1 mb-8 text-sm">
              {{ GetContent(item.Content) }}
            </div>
            <div>
              <router-link
                :to="`/news/${item.NewsID}`"
                class="inline-block px-8 py-2 text-sm font-bold text-white transition-all duration-300 border rounded-full md:px-10 md:py-3 bg-primary border-primary hover:md:bg-transparent hover:md:text-primary"
              >
                查看詳細
              </router-link>
            </div>
          </div>
        </li>
      </ol>

      <div class="w-full text-center">
        <router-link
          to="/news"
          class="inline-block px-20 py-3 font-semibold text-white transition-all duration-300 border rounded-full md:py-4 bg-primary hover:md:text-primary hover:md:bg-transparent border-primary"
          >查看更多文章</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getNewsListData } from "@/common/data_convert";
export default {
  name: "NewsListSection",
  methods: {
    GetContent(val) {
      var msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp

      return msg.slice(0, 100) + "...";
    },
  },
  computed: {
    ...mapState(["news_data", "news_category_data"]),
    page_news_data() {
      return getNewsListData(this.news_data.slice(0, 4));
    },
  },
};
</script>