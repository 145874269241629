<template>
  <main class="relative z-10 w-full">
    <Carousel />
    <div
      class="w-full max-w-[1440px] mx-auto relative z-10 md:px-10 px-5 py-10 md:py-20"
    >
      <img
        :src="$ImageUrl($GetColumn('home_ad_image_lg'))"
        class="hidden w-full md:block"
      />
      <img
        :src="$ImageUrl($GetColumn('home_ad_image_sm'))"
        class="block w-full md:hidden"
      />
    </div>
    <ProductList :product_data="promote_product_data" title="" />
    <NewsList />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import ProductList from "@/components/home/ProductList.vue";
import NewsList from "@/components/home/NewsList.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    ProductList,
    NewsList,
  },
  computed: {
    promote_product_category() {
      return this.$store.state.category_data.filter(
        (item) => item.Title == "首頁推薦商品"
      )[0];
    },
    promote_product_data() {
      return this.$store.getters.filter_product_data(
        this.promote_product_category.MenuID
      );
    },
  },
  mounted() {
    this.$PageInit(this, "");
  },
};
</script>
