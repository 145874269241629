<template>
  <section class="relative z-10 w-full">
    <div class="w-full max-w-[1440px] mx-auto relative md:px-10">
      <div class="relative w-full overflow-hidden md:rounded-2xl">
        <div ref="GlideSlider" class="glide">
          <div class="relative glide__track" data-glide-el="track">
            <ul class="relative z-0 glide__slides">
              <li
                class="relative w-full glide__slide"
                v-for="(item, item_index) in carousel_data"
                :key="`carousel_${item_index}`"
              >
                <a
                  :href="item.Link"
                  class="relative block w-full overflow-hidden aspect-square md:aspect-video"
                >
                  <img
                    :src="$ImageUrl(item.Image1)"
                    :alt="item.Title"
                    class="hidden object-cover w-full h-full md:block"
                  />
                  <img
                    :src="$ImageUrl(item.Image2)"
                    :alt="item.Title"
                    class="block object-cover w-full h-full md:hidden"
                  />
                </a>
              </li>
            </ul>

            <div
              class="absolute left-0 z-10 flex items-center justify-center w-full bottom-5 glide__bullets"
              data-glide-el="controls[nav]"
            >
              <button
                v-for="(item, item_index) in carousel_data"
                :key="`bullet_${item_index}`"
                class="w-2 h-2 mx-1 bg-white rounded-full glide__bullet"
                :data-glide-dir="`=${item_index}`"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.glide__bullet {
  opacity: 0.5;
}
.glide__bullet.glide__bullet--active {
  opacity: 1;
}
</style>

<script>
import "@glidejs/glide/dist/css/glide.core.min.css";
import Glide from "@glidejs/glide";
export default {
  name: "HomeCarousel",
  data() {
    return {
      glide_slider: null,
    };
  },
  computed: {
    carousel_data() {
      return this.$store.state.carousel_data;
    },
  },
  mounted() {
    this.glide_slider = new Glide(this.$refs.GlideSlider, {
      autoplay: 5000,
      gap: 0,
    });
    this.glide_slider.mount();
  },
};
</script>
