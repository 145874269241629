<template>
  <div class="fixed top-0 left-0 z-30 w-full">
    <router-link
      :to="$GetColumn('event_announcement_link')"
      v-if="$GetColumn('event_announcement') != ''"
      class="relative z-20 block py-3 text-sm font-medium text-center text-white bg-primary"
    >
      <p>{{ $GetColumn("event_announcement") }}</p>
    </router-link>
    <div class="w-full relative z-10 h-[70px] bg-white text-black">
      <div
        class="w-full max-w-[1920px] relative mx-auto flex items-center h-full px-5 justify-between"
      >
        <button
          @click="$emit('menu-action')"
          class="block h-full transition-colors duration-300 lg:hidden text-primary hover:md:text-zinc-500"
        >
          <i v-show="!menu_status" class="icon-menu"></i>
          <i v-show="menu_status" class="icon-close"></i>
        </button>
        <router-link
          to="/"
          class="absolute z-10 flex items-center h-full transform -translate-x-1/2 -translate-y-1/2 lg:translate-x-0 lg:translate-y-0 lg:relative lg:top-auto lg:left-0 left-1/2 top-1/2"
        >
          <img src="/img/logo.webp" class="block h-[40px] md:h-[50px] w-auto" />
        </router-link>

        <div class="flex items-center h-full">
          <ol class="items-center hidden h-full mr-5 lg:flex">
            <li
              v-for="(item, item_index) in menu_list"
              :key="`list_item_${item_index}`"
              class="flex items-center h-full"
            >
              <router-link
                class="flex items-center h-full px-3 text-sm font-bold transition-colors duration-300 text-zinc-700 hover:md:text-primary"
                :to="item.link"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ol>
          <ol class="flex items-center h-full -mr-2">
            <li class="hidden h-full lg:block">
              <button
                @click="$router.push('/account/login')"
                class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 text-primary hover:md:text-secondary"
              >
                <i class="icon-user"></i>
              </button>
            </li>
            <li class="hidden h-full lg:block">
              <button
                @click="$store.commit('SetSearchDialog', true)"
                class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 text-primary hover:md:text-secondary"
              >
                <i class="icon-magnifying-glass"></i>
              </button>
            </li>
            <li class="h-full">
              <button
                @click="$store.commit('SetShopCartStatus', true)"
                class="relative flex items-center justify-center h-full px-2 transition-colors duration-300 text-primary hover:md:text-secondary"
              >
                <div class="relative w-6">
                  <span
                    class="absolute -right-2 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block text-white transform rounded-full -top-1 bg-secondary scale-[.9]"
                    >{{ shopcart_count }}</span
                  >
                  <i class="relative z-0 icon-cart-shopping"></i>
                </div>
              </button>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div
      :class="add_cart_message ? 'translate-y-full ' : ' '"
      class="absolute bottom-0 left-0 right-0 z-0 text-black transition-transform duration-300 transform bg-secondary"
    >
      <div
        class="w-full max-w-[1440px] font-bold px-5 mx-auto xl:px-0 z-10 sm:px-10 py-4 relative flex items-center md:justify-center justify-between"
      >
        已將商品加入您的購物車！
        <span
          @click="OpenShopCart"
          class="block ml-2 text-xs font-medium underline cursor-pointer text-primary"
          >查看購物車</span
        >

        <button
          @click="CloseShopCartDialog"
          class="absolute hidden text-xl transform -translate-y-1/2 md:block xl:right-0 sm:right-10 right-5 top-1/2"
        >
          <i class="icon-close"></i>
        </button>
      </div>

      <div
        :class="
          add_cart_message
            ? 'duration-[5s]  scale-x-100'
            : ' scale-x-0 duration-100'
        "
        class="absolute top-0 bottom-0 left-0 right-0 z-0 transition-all ease-linear origin-left transform bg-white bg-opacity-20"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
      drop_down: false,
      dialog_timer: null,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("shopcart_module/SetAddCartMessage", false);
    },
  },
  computed: {
    shopcart_count() {
      let count = 0;
      this.$store.state.shopcart_module.shopcart.forEach((item) => {
        count += item.amount;
      });
      return count;
    },
    add_cart_message() {
      return this.$store.state.shopcart_module.add_cart_message;
    },
  },
  watch: {
    add_cart_message() {
      if (this.add_cart_message) {
        this.dialog_timer != null ? clearTimeout(this.dialog_timer) : "";
        this.dialog_timer = setTimeout(() => {
          this.CloseShopCartDialog();
        }, 5000);
      }
    },
  },
};
</script>
