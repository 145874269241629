export function getNewsListData(list) {
  let tmp_list = [];

  list.forEach((item) => {
    let content = getTextContent(item.Content).slice(0, 150);
    content.length > 150 ? (content = content.slice(0, 150) + "...") : "";
    tmp_list.push({
      NewsID: item.NewsID,
      Image: item.Image1,
      Title: item.Title,
      CategoryTitle: item.CategoryTitle,
      Content: content,
      Date: item.created_at.slice(0, 10),
    });
  });

  return tmp_list;
}

export function getProductListData(list) {
  let tmp_list = [];

  list.forEach((product) => {
    const stock = getLowestPriceStock(product);
    const discount = getDiscount(product);
    tmp_list.push({
      GoodsID: product.GoodsID,
      Title: product.Title,
      Price: stock.Price,
      SellPrice: stock.SellPrice,
      MemberSellPrice: stock.MemberSellPrice,
      Discount: discount,
      Image: product.Image1,
      Stock: product.Stock,
    });
  });

  return tmp_list;
}

export function getLowestPriceStock(item) {
  let active_stock = null;

  item.Stock.forEach((stock) => {
    if (active_stock == null) {
      active_stock = stock;
    } else if (parseInt(active_stock.SellPrice) > parseInt(stock.SellPrice)) {
      active_stock = stock;
    }
  });

  return active_stock;
}

function getTextContent(val) {
  var msg = val.replace(/<[^>]+>/g, ""); //去除HTML Tag
  msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
  msg = msg.replace(/&npsp;/gi, ""); //去掉npsp

  return msg;
}

function getDiscount(item) {
  let discount_type_list = [];
  item.Discount.forEach((discount) => {
    if (discount.Status != "N" && new Date() < new Date(discount.EndTime)) {
      if (discount.DiscountType == "D") {
        discount_type_list.indexOf("免運") == -1
          ? discount_type_list.push("免運")
          : "";
      } else if (discount.DiscountType == "P") {
        discount_type_list.indexOf("特價") == -1
          ? discount_type_list.push("特價")
          : "";
      } else if (discount.DiscountType == "G") {
        discount_type_list.indexOf("贈品") == -1
          ? discount_type_list.push("贈品")
          : "";
      } else if (discount.DiscountType == "S") {
        discount_type_list.indexOf("組合價") == -1
          ? discount_type_list.push("組合價")
          : "";
      }
    }
  });
  return discount_type_list;
}
