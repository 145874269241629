<template>
  <aside
    :class="menu_status ? 'translate-x-full' : ''"
    class="fixed top-[102px] transition-all duration-500 z-[29] transform overflow-x-hidden overflow-y-auto -left-full w-full bottom-0 bg-zinc-100"
  >
    <div class="relative z-0 flex flex-col w-full h-full">
      <ol class="flex-1 w-full pt-5">
        <li
          class="w-full px-5"
          v-for="(item, item_index) in menu_list"
          :key="`item_${item_index}`"
        >
          <router-link
            @click.native="$emit('menu-action', false)"
            class="block py-4 text-sm font-medium text-black transition-all duration-300 hover:md:opacity-50"
            :to="item.link"
          >
            <span class="block text-2xl font-bold uppercase text-primary">{{
              item.title
            }}</span>
            {{ item.sub_title }}
          </router-link>
        </li>
        <li class="w-full px-5">
          <button
            @click="OpenSearchDialog"
            class="block py-4 text-sm font-medium text-black transition-all duration-300 text-start hover:md:opacity-50"
          >
            <span class="block text-2xl font-bold uppercase text-primary"
              >搜尋商品</span
            >SEARCH PRODUCT
          </button>
        </li>
      </ol>
      <div class="px-5 pb-5">
        <router-link
          to="/account"
          @click.native="$emit('menu-action', false)"
          v-if="is_member"
          class="block w-full py-3 font-medium text-center text-white transition-all duration-300 border rounded-lg bg-primary hover:md:bg-transparent hover:md:text-primary border-primary"
        >
          會員中心
        </router-link>
        <router-link
          to="/account/login"
          @click.native="$emit('menu-action', false)"
          v-else
          class="block w-full py-3 font-medium text-center text-white transition-all duration-300 border rounded-lg bg-zinc-700 hover:md:bg-transparent hover:md:text-zinc-700 border-zinc-700"
        >
          登入 / 註冊
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        // {
        //   title: "關於我們",
        //   sub_title: "About Us",
        //   link: "/about",
        // },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        // {
        //   title: "影音專區",
        //   sub_title: "Videos",
        //   link: "/video",
        // },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
      sub_menu_open: false,
    };
  },
  methods: {
    OpenSearchDialog() {
      this.$emit("menu-action", false);
      this.$store.commit("SetSearchDialog", true);
    },
  },
  watch: {
    menu_status() {
      if (!this.menu_status) {
        this.sub_menu_open = false;
      }
    },
  },
  computed: {
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token");
    },
  },
};
</script>
