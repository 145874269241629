<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-30 flex items-center justify-center w-full h-screen left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 w-11/12 max-w-md px-10 bg-white py-14 rounded-xl"
    >
      <p class="mb-10 text-2xl font-bold text-center text-primary">搜尋商品</p>
      <div class="mb-5">
        <input
          type="text"
          autofocus
          v-model="key_word"
          placeholder="請輸入商品關鍵字"
          class="w-full px-4 py-2 border rounded-md border-secondary"
        />
      </div>
      <div class="flex justify-center w-full">
        <button
          @click="Search"
          class="w-1/2 px-4 py-2 font-bold text-white transition-colors duration-200 border rounded-full bg-primary hover:md:text-primary hover:md:bg-transparent border-primary"
        >
          搜尋
        </button>
      </div>
    </div>
    <div
      data-dialog-background
      @click="Close"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-opacity-50 bg-zinc-200"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "@/gsap/MainDialog";
export default {
  name: "MainDialog",
  data() {
    return {
      dialog_gsap: null,
      key_word: "",
    };
  },
  methods: {
    Close() {
      this.$store.commit("SetSearchDialog", false);
    },
    Search() {
      if (this.key_word != "") {
        this.$router.push(`/search/${this.key_word}`);
        this.Close();
        this.key_word = "";
      }
    },
  },
  watch: {
    dialog_status() {
      this.dialog_status ? this.dialog_gsap.open() : this.dialog_gsap.close();
    },
  },
  computed: {
    dialog_status() {
      return this.$store.state.search_dialog;
    },
  },
  mounted() {
    this.dialog_gsap = new Dialog(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
