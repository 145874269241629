<template>
  <section class="relative z-10 w-full pb-20">
    <div class="w-full max-w-[1440px] mx-auto md:px-10 px-5 relative z-10">
      <ul class="flex flex-wrap items-stretch mb-10 -mx-2 overflow-hidden">
        <li
          class="flex-shrink-0 w-1/2 px-2 mb-4 md:w-1/3 lg:w-1/4"
          v-for="(item, item_index) in product_data"
          :key="`product_${item_index}`"
        >
          <div class="w-full">
            <router-link
              :to="`/product/${item.GoodsID}`"
              class="relative block w-full mb-2 overflow-hidden rounded-md aspect-square"
            >
              <span
                v-if="GetDiscount(item).indexOf('特價') != -1"
                class="absolute sm:top-3 top-2 left-2 sm:left-3 rounded-full z-10 px-3 sm:px-5 py-[3px] text-xs sm:text-sm font-medium text-white bg-red-500"
              >
                特價
              </span>

              <div
                :class="CheckProductStockStatus(item) ? 'hidden' : 'flex'"
                class="absolute top-0 bottom-0 left-0 right-0 z-20 items-center justify-center bg-white bg-opacity-60"
              >
                <span
                  class="flex items-center justify-center w-24 h-24 text-xl font-medium text-white bg-black rounded-full bg-opacity-80"
                  >已售完</span
                >
              </div>
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="item.Title"
                class="relative z-0 object-cover w-full h-full transition-all duration-300 hover:md:opacity-60 hover:scale-105"
              />
            </router-link>
            <div class="flex items-center mb-1">
              <span
                v-if="GetDiscount(item).indexOf('免運') != -1"
                class="rounded-full z-10 mr-1 px-3 py-[3px] text-xs font-medium text-white bg-primary"
              >
                免運
              </span>
              <span
                v-if="GetDiscount(item).indexOf('贈品') != -1"
                class="rounded-full z-10 px-3 py-[3px] text-xs font-medium text-white bg-zinc-600"
              >
                贈品
              </span>
              <span
                v-if="item.Discount.indexOf('組合價') != -1"
                class="rounded-full z-10 px-3 py-[3px] text-xs font-medium text-white bg-secondary"
              >
                組合價
              </span>
            </div>
            <h4 class="max-w-full mb-2 text-sm font-semibold">
              {{ item.Title }}
            </h4>
            <div class="flex items-center">
              <p
                v-if="item.Stock[0].Price != item.Stock[0].SellPrice"
                class="mr-2 text-xs font-bold line-through text-zinc-500"
              >
                NT${{ $MoneyFormat(item.Stock[0].Price) }}
              </p>
              <p class="text-sm font-bold text-primary">
                NT${{ $MoneyFormat(item.Stock[0].SellPrice) }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div class="text-center">
        <router-link
          to="/collections"
          class="px-20 py-3 font-semibold text-white transition-all duration-300 border rounded-full md:py-4 bg-primary hover:md:text-primary hover:md:bg-transparent border-primary"
        >
          瀏覽全部商品
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductListSection",
  props: {
    title: {
      type: String,
    },
    product_data: {
      type: Array,
    },
  },
  methods: {
    GetDiscount(item) {
      let discount_type_list = [];
      item.Discount.forEach((discount) => {
        if (discount.Status != "N" && new Date() < new Date(discount.EndTime)) {
          if (discount.DiscountType == "D") {
            discount_type_list.indexOf("免運") == -1
              ? discount_type_list.push("免運")
              : "";
          } else if (discount.DiscountType == "P") {
            discount_type_list.indexOf("特價") == -1
              ? discount_type_list.push("特價")
              : "";
          } else if (discount.DiscountType == "G") {
            discount_type_list.indexOf("贈品") == -1
              ? discount_type_list.push("贈品")
              : "";
          } else if (discount.DiscountType == "S") {
            discount_type_list.indexOf("組合價") == -1
              ? discount_type_list.push("組合價")
              : "";
          }
        }
      });
      return discount_type_list;
    },
    CheckProductStockStatus(product) {
      let stock_count = 0;
      product.Stock.forEach((stock) => {
        stock.Status == "Y" ? (stock_count += stock.Stock) : "";
      });

      return stock_count <= 0 ? false : true;
    },
  },
};
</script>
