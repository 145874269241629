import Vue from "vue";
import Vuex from "vuex";
import { getAllData } from "@/api/page_data.js";

import { shopcart_module } from "./shopcart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_dialog: {
      status: false, //系統訊息顯示狀態
      content: "", //系統訊息內容
    },
    image_loaded: false, //頁面圖片讀取狀態
    member_token: "", //會員token
    search_dialog: false, //搜尋商品視窗顯示狀態
    shop_cart_open: false, //購物車顯示狀態
    loading: 0, //Loading狀態，大於0則為讀取中，等於0則為讀取完畢
    common_column_data: null, //通用欄位資料
    carousel_data: null, //首頁輪播資料
    news_data: null, //最新消息資料
    news_category_data: null, //最新消息分類資料
    menu_category_data: null, //商品目錄資料
    category_data: null, //商品分類資料
    event_data: null, //獨立銷售頁資料
    all_product_data: null, //所有商品資料，包含已刪除以及停用的商品
    product_data: null, //商品資料
    zipcode_data: null, //縣市郵遞區號資料
    payment_data: null, //付款方式資料
    shipway_data: null, //物流方式資料
  },
  getters: {
    // 取得資料讀取狀況
    all_data_loaded(state) {
      if (
        state.common_column_data != null &&
        state.carousel_data != null &&
        state.news_data != null &&
        state.news_category_data != null &&
        state.category_data != null &&
        state.product_data != null &&
        state.zipcode_data != null &&
        state.payment_data != null &&
        state.shipway_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 取得指定ID的獨立銷售頁資料
    event_data: (state) => (key) => {
      if (state.event_data == null) {
        return null;
      }
      const event_data = state.event_data.filter((item) => item.MenuID == key);
      if (event_data.length <= 0) {
        return "error";
      }
      return event_data.length > 0 ? event_data[0] : "error";
    },
    // 取得指定分類的商品
    filter_product_data: (state) => (category_id) => {
      if (state.product_data == null) {
        return null;
      }
      if (category_id == "all" || category_id == "") {
        return state.product_data;
      } else {
        return state.product_data.filter((item) => {
          return (
            item.Menu.filter((menu) => menu.MenuID == category_id).length > 0
          );
        });
      }
    },
  },
  mutations: {
    // 設定頁面圖片讀取狀態
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    // 設定購物車顯示狀態
    SetShopCartStatus(state, action) {
      state.shop_cart_open = action;
    },
    // 設定Loading狀態
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : "";
      }
    },
    // 設定系統訊息內容與狀態
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    // 設定搜尋商品視窗顯示狀態
    SetSearchDialog(state, status) {
      state.search_dialog = status;
    },
    // 依照對應的key設定State資料
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData(state) {
      getAllData().then((res) => {
        state.commit("SetStateData", {
          key: "common_column_data",
          val: res.data.Column.data,
        });
        state.commit("SetStateData", {
          key: "carousel_data",
          val: res.data.Carousel.data,
        });
        state.commit("SetStateData", {
          key: "news_data",
          val: res.data.News.data,
        });
        state.commit("SetStateData", {
          key: "news_category_data",
          val: res.data.NewsCategory.data,
        });
        state.commit("SetStateData", {
          key: "zipcode_data",
          val: res.data.ZipCode.data,
        });
        state.commit("SetStateData", {
          key: "payment_data",
          val: res.data.Payment.data,
        });
        state.commit("SetStateData", {
          key: "shipway_data",
          val: res.data.Shipping.data,
        });

        state.dispatch("getCategoryData", res.data.Menu.data);
        state.dispatch("getParentCategoryData", res.data.MenuCategory.data);
        state.dispatch("getProductData", res.data.Goods.data);
      });
    },
    // 取得商品分類與獨立銷售頁資料
    getCategoryData(state, data) {
      // 依照後台設定的排序，排序資料
      let tmp_data = data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      // 商品分類為Content5不等於獨立銷售頁的資料
      const category = tmp_data.filter((item) => {
        if (item.MenuTimeEnd != null) {
          return (
            item.Content5 != "獨立銷售頁" &&
            new Date() < new Date(item.MenuTimeEnd)
          );
        } else {
          return item.Content5 != "獨立銷售頁";
        }
      });
      state.commit("SetStateData", {
        key: "category_data",
        val: category,
      });

      // 獨立銷售頁為Content5等於獨立銷售頁的資料
      const event = tmp_data.filter((item) => item.Content5 == "獨立銷售頁");
      state.commit("SetStateData", {
        key: "event_data",
        val: event,
      });
    },
    // 取得商品目錄
    getParentCategoryData({ commit }, data) {
      // 篩選不顯示的目錄
      let tmp_data = data.filter((item) => {
        return item.Status != "N";
      });
      commit("SetStateData", {
        key: "menu_category_data",
        val: tmp_data,
      });
    },
    // 取得商品資料
    getProductData({ commit }, data) {
      // 篩選已刪除商品
      let tmp_data = data.filter((item) => {
        return item.deleted_at == null;
      });
      // 篩選停用的庫存，並判斷若商品沒有圖片則顯示預設圖片
      tmp_data.forEach((item, item_index) => {
        tmp_data[item_index].Stock = item.Stock.filter(
          (stock) => stock.Status == "Y"
        );
        if (item.Image1 == "") {
          tmp_data[item_index].Image1 = "/image/product_default.webp";
        }
      });
      // 篩選掉沒有庫存選項的一般商品
      tmp_data = tmp_data.filter(
        (item) => item.Stock.length > 0 || item.IsCustom == "Y"
      );

      // 篩選停用的商品
      tmp_data = tmp_data.filter((item) => item.Status == "Y");
      // 排序
      tmp_data = tmp_data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      commit("SetStateData", {
        key: "all_product_data",
        val: data,
      });
      commit("SetStateData", {
        key: "product_data",
        val: tmp_data,
      });
      commit("shopcart_module/SetProductData", tmp_data, {
        root: true,
      });
    },
  },
  modules: {
    shopcart_module: shopcart_module,
  },
});
